<template>
  <el-dialog
    :visible.sync="xppProjectMemberInfo.status"
    :close-on-click-modal="false"
    :append-to-body="true"
    width="640px"
    custom-class="xpp-project-member-management"
  >
    <div slot="title">
      <h2>管理合作者</h2>
      <h3>{{ xppProjectMemberInfo.title }}</h3>
    </div>
    <div class="view-container">
      <div class="view-item">
        <div class="xpp-projectinfo-title">
          <span class="text-overflow">paper成员</span
          ><span class="xpp-projectinvite-btn" @click="invitationToJoinShow"
            >邀请paper成员</span
          >
        </div>
        <ul class="xpp-project-memberlist xpp-scrollbar">
          <li
            v-for="item in xppProjectMemberInfo.memberlist"
            :key="item.userId"
          >
            <div
              class="xpp-user-photo"
              :style="item.headImg | styleUserPhoto"
            ></div>
            <div class="xpp-member-info-container">
              <b>{{ item.nickName }}</b>
              <b v-if="item.userId === xppUserInfo.id">（我）</b>
              <br />
              <span>{{ item.email }}</span>
            </div>
            <div
              class="xpp-project-memberadmin-btn"
              v-if="
                xppProjectMemberInfo.observerAuthority &&
                  item.userId !== xppUserInfo.id
              "
            >
              <el-select v-model="value">
                <el-option label="可编辑" value="1"></el-option>
                <el-option label="可评论" value="2"></el-option>
                <el-option label="可查看" value="3"></el-option>
              </el-select>
              <el-button
                type="info"
                icon="el-icon-close"
                circle
                size="mini"
                @click="memberRemove('member', item)"
              ></el-button>
            </div>
          </li>
        </ul>
      </div>
      <div class="view-item">
        <div class="xpp-projectinfo-title">
          <span class="text-overflow">外部合作者</span
          ><span class="xpp-projectinvite-btn" @click="clsoe"
            >邀请外部合作者</span
          >
        </div>
        <ul class="xpp-project-memberlist xpp-scrollbar">
          <li
            v-for="item in xppProjectMemberInfo.partnerlist"
            :key="item.userId"
          >
            <div
              class="xpp-user-photo"
              :style="item.headImg | styleUserPhoto"
            ></div>
            <div class="xpp-member-info-container">
              <b>{{ item.nickName }}</b>
              <br />
              <span>{{ item.email }}</span>
            </div>
            <div class="xpp-project-memberadmin-btn">
              <el-select v-model="value">
                <el-option label="可编辑" value="1"></el-option>
                <el-option label="可评论" value="2"></el-option>
                <el-option label="可查看" value="3"></el-option>
              </el-select>
              <el-button
                type="info"
                icon="el-icon-close"
                circle
                size="mini"
                @click="memberRemove('partner', item)"
              ></el-button>
            </div>
          </li>
          <li
            class="xpp-member-unmanned"
            data-label="无合作者"
            v-if="!xppProjectMemberInfo.partnerlist.length"
          ></li>
        </ul>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "PaperMemberManagement",
  computed: {
    ...mapGetters(["xppUserInfo", "xppProjectMemberInfo"])
  },
  data() {
    return {
      show: true,
      value: "1"
    };
  },
  methods: {
    clsoe() {
      this.$UpdataVuexState({
        key: "xppProjectMemberInfo",
        data: { status: false }
      });
    },
    invitationToJoinShow() {
      let { projetcId: id, title } = this.xppProjectMemberInfo;
      this.$DialogInvitationToJoinShow({
        id,
        type: 1,
        title,
        visible: true,
        resourceType: "1"
      });
    },
    async memberRemove(key, val) {
      let list = this.xppProjectMemberInfo[key + "list"];
      let params = new FormData();
      params.append("selectUserId", val.userId);
      params.append("resourceId", val.resourceId);
      await this.$axios.post("/per/m/remove", params);
      for (let i = 0; i < list.length; i++) {
        if (list[i].userId === val.userId) {
          list.splice(i, 1);
        }
      }
      let info = {
        key: "xppProjectMemberInfo",
        data: { status: false }
      };
      info.data[key + "list"] = list;
      this.$UpdataVuexState(info);
    },
    ...mapMutations(["$UpdataVuexState", "$DialogInvitationToJoinShow"])
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  h2 {
    margin-bottom: 0.8em;
    font-size: 16px;
    font-weight: 600;
  }
  h3 {
    line-height: 20px;
    font-size: 14px;
  }
  .xpp-project-memberadmin-btn {
    width: 134px;
    display: flex;
    align-items: center;
    .el-button {
      padding: 2px;
      margin-left: 12px;
    }
  }
  .el-input__inner {
    border-color: transparent;
  }
}
.xpp-projectinfo-title {
  display: flex;
  padding-bottom: 6px;
  margin-bottom: 6px;
  box-shadow: inset 0 -1px 0 0 #eee;
  .text-overflow {
    flex: auto;
    color: #8e959a;
  }
  .xpp-projectinvite-btn {
    flex: none;
    color: #cf8d1f;
    cursor: pointer;
  }
}
.xpp-project-memberlist {
  min-height: 135px;
  max-height: 305px;
  margin-bottom: 30px;
  &::-webkit-scrollbar {
    width: 11px;
  }
  li {
    padding: 5px;
    display: flex;
    align-items: center;
    font-size: 0;
  }
  .xpp-user-photo {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    background-color: #999;
  }
  b {
    font-weight: 600;
    font-size: 13px;
    color: #373839;
    line-height: 16px;
  }
  span {
    font-size: 13px;
    color: #8e959a;
    line-height: 16px;
  }
}
.xpp-member-info-container {
  flex: auto;
}
.xpp-member-unmanned {
  display: block !important;
  background: url(/img/modal-list-fallback.svg) center 10px/65px no-repeat;
  width: 100%;
  text-align: center;
  padding-top: 75px !important;
  padding-bottom: 15px !important;
  &::before {
    content: attr(data-label);
    font-size: 12px;
    color: #757575;
    line-height: 15px;
  }
}
</style>
