import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  computed: {
    notiActive() {
      return {
        "icon-active": this.dialogElementShow.noticeContainer
      };
    },
    ...mapGetters([
      "viewMaskShow",
      "dialogElementShow",
      "xppUserInfo",
      "noticeListPageInfo"
    ])
  },
  data() {
    return {};
  },
  methods: {
    openSearchCtrl() {
      this.$DiaLoginFoUpDate({
        key: "searchCtrl",
        value: true
      });
    },
    xpaperMessage() {
      this.$UpdataNoticeListInfo();
      this.$DiaLoginFoUpDate({ key: "noticeContainer", value: true });
      this.$UpdataVuexState({ key: "viewMaskShow", data: true });
    },
    userCtrlOff() {
      this.$DiaLoginFoUpDate({
        key: "userCtrl",
        value: true
      });
      this.$UpdataVuexState({ key: "viewMaskShow", data: true });
    },
    ...mapMutations([
      "$UpdataVuexState",
      "$DiaLoginFoUpDate",
      "$SaveNoticeListPageInfo"
    ]),
    ...mapActions(["$UpdataNoticeListInfo"])
  }
};
