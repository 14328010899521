<template>
  <div class="xpp-breadcrumb-container" v-if="paperCategoryList.length > 0">
    <template v-if="authvisiter">
      <router-link
        class="xpp-breadcrumb-item"
        v-for="item in listSlice"
        :key="item.id"
        :to="`/${item.type}/${item.id}`"
        rel="noopener"
        tag="div"
      >
        <span>{{ item.name }}</span> <i class="iconfont icon-xiangxia"></i
      ></router-link>
      <el-tooltip
        content="详情"
        placement="bottom"
        :enterable="false"
        :disabled="paperInfoState"
      >
        <div
          class="xpp-breadcrumb-item title-info-container"
          v-popover:paperInfoPopover
        >
          <i class="iconfont icon-shoucang1" v-if="paperCollectionState"></i>
          <span>{{ listLase.name }}</span>
          <i class="iconfont icon-xiangxia"></i>
        </div>
      </el-tooltip>
      <el-popover
        ref="paperInfoPopover"
        placement="bottom-start"
        v-model="paperInfoState"
        :visible-arrow="false"
        width="274"
        trigger="click"
        :close-delay="0"
        @show="paperInfoShow"
        @hide="paperInfoHide"
      >
        <div class="title-paperinfo-container">
          <div class="title-paperinfo-body">
            <h3>
              <span>{{ listLase.name }}</span>
              <i
                class="iconfont icon-edit-outline"
                @click="openChangePaperNameDialog"
              ></i>
              <i
                class="iconfont"
                :class="iconCollection"
                @click="setCollectionState"
              ></i>
            </h3>
            <p class="title-paperinfo-text" data-label="所有者">
              <span>：{{ paperOwnerInfo.nickName }}</span>
            </p>
            <p class="title-paperinfo-text" data-label="最后修改">
              <span>：{{ paperOwnerInfo.updateDate }}</span>
            </p>
            <div class="member-face-container display-flex flex-start">
              <el-tooltip
                placement="bottom"
                :enterable="false"
                content="paper分享"
              >
                <div
                  class="member-face-item member-face-sharebtn"
                  @click="openShareDialog"
                ></div>
              </el-tooltip>
              <el-tooltip
                placement="bottom"
                :enterable="false"
                v-for="item in memberslice"
                :content="item.nickName"
                :key="item.userId"
              >
                <div
                  class="member-face-item xpp-user-photo"
                  :style="item.headImg | styleUserPhoto"
                ></div>
              </el-tooltip>
              <div class="member-number-text" v-if="paperMemberlist.length > 3">
                +{{ paperMemberlist.length }}
              </div>
            </div>
            <XppAllProjectList
              :view-off="paperInfoState"
              @back="paperInfoHide"
            />
          </div>
        </div>
      </el-popover>
      <!--  paper_page 标题修改  -->
      <el-dialog
        :visible.sync="changePaperNameDialog"
        :close-on-click-modal="false"
        :append-to-body="true"
        width="440px"
        @open="paperNameEditTitle = listLase.name"
      >
        <h2 class="paper-title-name" slot="title">修改Paper名称</h2>
        <el-row>
          <label class="paper-title-edit-label">
            <input
              class="paper-title-edit-form"
              ref="paperTitleEditInput"
              type="text"
              @compositionstart="updatePaperNameOff = false"
              @compositionend="updatePaperNameOff = true"
              @keydown.enter="updatePaperName"
              v-model="paperNameEditTitle"
              placeholder="请输入页面标题"
            />
          </label>
        </el-row>
        <el-row class="paper-title-edit-footer text-right">
          <el-button plain @click="changePaperNameDialog = false"
            >取消</el-button
          >
          <el-button type="warning" @click="updatePaperName">保存</el-button>
        </el-row>
      </el-dialog>
    </template>
    <template v-else>
      <div class="xpp-breadcrumb-item title-info-container">
        <span>{{ listLase.name }}</span>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import XppAllProjectList from "@components/XppAllProjectList";

export default {
  name: "XppBreadcrumb",
  props: {
    authvisiter: {
      type: Boolean,
      required: true
    }
  },
  components: {
    XppAllProjectList
  },
  computed: {
    listSlice() {
      return this.paperCategoryList.slice(0, this.paperCategoryList.length - 1);
    },
    listLase() {
      return this.paperCategoryList.slice(-1)[0];
    },
    memberslice() {
      return this.paperMemberlist.slice(0, 3);
    },
    paperMemberlist() {
      return this.xppResourceMemberList.memberlist;
    },
    iconCollection() {
      return {
        "icon-shoucang1": this.paperCollectionState,
        "icon-shoucang2": !this.paperCollectionState
      };
    },
    ...mapGetters([
      "paperGroupId",
      "xppCompanyId",
      "paperOwnerInfo",
      "paperCategoryList",
      "xppResourceMemberList"
    ])
  },
  data() {
    return {
      paperInfoState: false,
      paperCollectionState: false,
      paperNameEditTitle: "",
      changePaperNameDialog: false,
      updatePaperNameOff: false // keydown 完整事件链判定
    };
  },
  methods: {
    async paperInfoShow() {
      this.paperInfoState = true;
    },
    paperInfoHide() {
      this.paperInfoState = false;
    },
    async updateCollectionList() {
      this.paperCollectionState = await this.$GetCollectionList();
    },
    async updatePaperName({ target }) {
      if (target.tagName === "INPUT" && !this.updatePaperNameOff) {
        return;
      }
      let params = new FormData();
      params.append("paperId", this.listLase.id);
      params.append("paperName", this.paperNameEditTitle);
      await this.$axios.post("/dis/p/update", params);
      Object.assign(this.paperCategoryList[this.paperCategoryList.length - 1], {
        name: this.paperNameEditTitle
      });
      this.changePaperNameDialog = false;
      document.title = this.paperNameEditTitle;
    },
    openChangePaperNameDialog() {
      this.changePaperNameDialog = true;
      this.$nextTick(() => {
        this.$refs.paperTitleEditInput.focus();
      });
    },
    openShareDialog() {
      this.paperInfoHide();
      this.$SetXppPaperShareDialogInfo({
        id: this.paperGroupId,
        name: document.title
      });
    },
    async setCollectionState() {
      await this.$SetCollectionState({
        id: this.paperGroupId,
        type: "2",
        flag: this.paperCollectionState ? 0 : 1
      });
      this.paperCollectionState = !this.paperCollectionState;
    },
    ...mapActions([
      "$SetXppPaperShareDialogInfo",
      "$GetCollectionList",
      "$SetCollectionState"
    ])
  }
};
</script>

<style lang="scss" scoped>
.xpp-breadcrumb-container {
  display: flex;
  align-items: center;
  color: #8e959a;
  font-weight: 600;
  span {
    cursor: pointer;
    padding-right: 10px;
  }
  i {
    color: #8e959a;
    font-size: 12px;
    transform: rotate(-90deg) scale(0.85);
  }
  .xpp-breadcrumb-item {
    flex: none;
    display: flex;
    align-items: center;
    margin-right: 10px;
    font-size: 14px;
    &:hover {
      color: #f5a623;
    }
  }
  .title-info-container {
    margin-right: 0;
    color: #373839;
    &:hover {
      color: #373839;
    }
    span {
      padding-right: 8px;
    }
    i {
      font-weight: bold;
      transform: rotate(0) scale(0.85);
    }
    .icon-shoucang1 {
      font-size: 16px;
      margin-right: 10px;
    }
  }
}
.title-paperinfo-body {
  padding: 8px 6px;
  color: #373839;
  h3 {
    margin-bottom: 10px;
    font-size: 16px;
    span {
      margin-right: 12px;
    }
    .iconfont {
      color: #c2c6d1;
      font-weight: 400;
      width: 20px;
      height: 20px;
      text-align: center;
      line-height: 20px;
      margin-right: 4px;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .title-paperinfo-text {
    font-size: 12px;
    display: flex;
    align-items: center;
    height: 26px;
    line-height: 26px;
    &::before {
      content: attr(data-label);
      color: #9197a3;
      width: 5em;
    }
    span {
      &:first-child {
        color: #9197a3;
      }
    }
  }
  .member-face-item {
    width: 26px;
    height: 26px;
    border-radius: 100%;
    background-color: #a5adb6;
    box-shadow: 0 0 0 2px #fff;
    margin-left: -3px;
    &:first-child,
    &:last-child {
      margin-left: 0;
    }
  }
  .member-face-sharebtn {
    margin-right: 11px;
    background-color: #fff;
    border: 2px dashed #c2c6d1;
    color: #c2c6d1;
    text-align: center;
    line-height: 22px;
    font-size: 20px;
    box-sizing: border-box;
    cursor: pointer;
    &:hover {
      color: #fff;
      border-color: transparent;
      background-color: #f5a623;
    }
    &:before {
      content: "+";
    }
  }
  .member-number-text {
    width: 26px;
    height: 26px;
    line-height: 26px;
    text-align: center;
    font-size: 12px;
    color: #9197a3;
    margin-left: 10px;
  }
}
.member-face-container {
  position: relative;
  margin-top: 20px;
  padding-bottom: 20px;
  &::after {
    content: "";
    position: absolute;
    left: -18px;
    right: -18px;
    bottom: 0;
    border-bottom: 1px solid #e9ebef;
  }
}
.paper-title-name {
  font-weight: bold;
  font-size: 16px;
}
.paper-title-edit-label {
  display: flex;
  input {
    width: 100%;
    &:focus {
      border-color: #f5a623;
    }
  }
}

.paper-title-edit-form {
  border: 1px solid #dbdee6;
  line-height: 1.5;
  font-weight: 500;
  padding: 0.55em 1em;
  font-size: 14px;
  box-shadow: 0 0 0 1px transparent;
  border-collapse: collapse;
  border-radius: 4px;
}

.paper-title-edit-footer {
  margin-top: 30px;
}

.el-button--default:hover,
.el-button--default:focus {
  color: #787878;
  background-color: #ebeef2;
  border-color: #dbdee6;
}
</style>
