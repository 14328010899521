<template>
  <div id="PaperHead" v-if="paperGroupId">
    <div class="paper-head-nav-box">
      <img
        class="paper-logo-nav"
        src="/img/ic_colored.png"
        data-to="/project"
        @click="routerJump"
        alt="logo"
      />
      <XppBreadcrumb :authvisiter="authvisiter" />
      <span class="xpp-paperview-weight" v-if="paperOperateMode !== 2">
        <template v-if="authvisiter">
          {{ paperOperateMode === 3 ? "[可查看 + 可评论]" : "[仅查看]" }}
        </template>
        <template v-else>
          [游客]
        </template>
      </span>
    </div>
    <div class="paper-head-user-main-box" v-if="authvisiter">
      <div class="xpp-user-online-info">
        <template v-for="item in onlinePeopleList">
          <el-tooltip
            class="paper-online-item"
            effect="dark"
            placement="bottom"
            :key="item.userId"
            :content="item.nickName"
          >
            <div
              class="xpp-user-photo"
              :style="item.headImg | styleUserPhoto"
            ></div>
          </el-tooltip>
        </template>
      </div>
      <div class="index-header-modules display-flex">
        <template v-if="paperOperateMode === 2">
          <div class="header-modules-item share-limit">
            <div class="xpp-paper-share-btn" v-popover:shareContextMenu>
              <i class="iconfont icon-share1"></i>
            </div>
            <el-popover
              ref="shareContextMenu"
              placement="bottom-end"
              :visible-arrow="false"
              popper-class="xpp-popover-container"
              width="257"
              trigger="click"
            >
              <ul class="xpp-paper-share-container">
                <li @click="openShareDialog">
                  <div class="display-flex flex-start">
                    <i class="iconfont icon-share"></i><span>分享给其他人</span>
                  </div>
                  <p>添加合作者 或 获取可协同链接</p>
                </li>
                <li @click="xppExportPdfDialogShow">
                  <div class="display-flex flex-start">
                    <i class="iconfont icon-pdf2"></i><span>导出PDF</span>
                  </div>
                  <p>导出高清Paper</p>
                </li>
              </ul>
            </el-popover>
          </div>

          <div class="header-modules-item">
            <div
              class="xpp-paper-more-btn"
              :class="moreActive"
              v-popover:moreContextMenu
            >
              <i class="el-icon-more"></i>
            </div>
            <el-popover
              ref="moreContextMenu"
              placement="bottom-end"
              :visible-arrow="false"
              popper-class="xpp-popover-container"
              width="242"
              trigger="click"
              @show="moreContextMenuState = true"
              @hide="moreContextMenuState = false"
            >
              <ul class="xpp-paper-more-container" @click="paperMoreSelect">
                <li data-type="paper_print">
                  <i class="iconfont icon-print"></i><span>打印</span>
                </li>
                <template v-if="$route.name === 'Paper'">
                  <li data-type="paper_backup">
                    <i class="iconfont icon-backup"></i><span>备份还原</span>
                  </li>
                  <template v-if="xppProjectMemberInfo.observerAuthority">
                    <li
                      data-type="paper_password"
                      data-state="2"
                      v-if="!paperIsPwd"
                    >
                      <i class="iconfont icon-lock"></i><span>设置密码</span>
                    </li>
                    <template v-else>
                      <li data-type="paper_password" data-state="6">
                        <i class="el-icon-unlock"></i><span>修改密码</span>
                      </li>
                      <li data-type="paper_password" data-state="7">
                        <i class="el-icon-error"></i><span>关闭密码</span>
                      </li>
                    </template>
                  </template>
                </template>
                <li data-type="size_page">
                  <i class="iconfont icon-page-size-change-icon"></i
                  ><span>更改页面大小</span>
                </li>
              </ul>
            </el-popover>
          </div>
        </template>

        <div class="header-modules-item">
          <div class="search-head-btn" @click="openSearchCtrl">
            <i class="iconfont icon-sousuo"></i>
          </div>
        </div>
        <div class="header-modules-item">
          <div class="system-notification" @click="xpaperMessage">
            <el-badge
              :value="noticeListPageInfo.unReadCount"
              :hidden="!(noticeListPageInfo.unReadCount > 0)"
              :max="99"
              class="item"
            >
              <i class="iconfont icon-tongzhi"></i>
            </el-badge>
          </div>
        </div>
        <div class="header-modules-item" v-if="xppUserInfo">
          <div class="user-profile-photo display-flex" @click="userCtrlOff">
            <div
              class="xpp-user-photo"
              :style="xppUserInfo.headImg | styleUserPhoto"
            ></div>
            <i class="iconfont icon-xiangxia"></i>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="xpp-button" @click="visitorLogin">登录</div>

    <el-dialog
      :visible.sync="paperbackup"
      :append-to-body="true"
      :close-on-click-modal="false"
      custom-class="paperbackup-dialog"
      width="720px"
    >
      <h2 class="paperbackup-title" slot="title">备份/还原</h2>
      <div class="paperbackup-body" @click="paperMoreSelect">
        <div
          class="paperbackup-select-item display-flex flex-start"
          data-type="paperdataexport"
        >
          <i class="el-icon-download"></i>
          <div>
            <b>导出和备份</b>
            <br />
            <span>您可以在您的计算机上创建Paper的副本</span>
          </div>
        </div>
        <div
          class="paperbackup-select-item display-flex flex-start"
          data-type="paperdatacover"
        >
          <i class="el-icon-upload2"></i>
          <div>
            <b>导入和恢复</b>
            <br />
            <span>请导入您的*.xcanvas备份文件，便可恢复Paper</span>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 打印paper -->
    <el-dialog
      :visible.sync="paperprint"
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      width="300px"
      custom-class="paperprint-dialog"
    >
      <h2 class="paperprint-title" slot="title">页面准备中</h2>
      <div class="paperprint-body">
        <div class="xpp-printinit-progress">
          <div :style="printinitProgressStyle"></div>
        </div>
        <div v-loading="true"></div>
      </div>
    </el-dialog>
    <!-- 导出pdf -->
    <el-dialog
      :visible.sync="xppExportPdfDialog"
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      width="480px"
      custom-class="paperprint-dialog"
    >
      <h2 class="paperprint-title display-flex" slot="title">
        <span>导出PDF</span>
        <i class="el-icon-close" @click="xppExportPdfDialogHide"></i>
      </h2>
      <div class="paperprint-body">
        <p>
          您可以将此Paper下载为PDF文件。您将通过电子邮件发送该Paper的PDF文件。在高峰时期，这可能需要几分钟。
        </p>
        <div class="paperprint-item">
          <span class="paperprint-label">邮件主题</span>
          <input
            class="xpp-input-text"
            type="text"
            placeholder="输入主题"
            v-model="xppExportPdfConfig.title"
          />
        </div>
        <div class="paperprint-item">
          <span class="paperprint-label" ref="emailInput">邮箱</span>
          <input
            class="xpp-input-text"
            :class="inputVerify"
            type="text"
            placeholder="输入邮箱地址"
            v-model="xppExportPdfConfig.email"
            @keydown.enter="xppEmailVerify"
          />
        </div>
        <div class="paperprint-item">
          <span class="paperprint-label">将邮件发送至：</span>
          <div class="paperprint-email-box" @click.stop="emailListSplice">
            <span
              v-for="(item, index) in xppExportPdfConfig.emailList"
              :key="item"
              ><b>{{ item }}</b
              ><i class="el-icon-close" data-type="del" :data-index="index"></i
            ></span>
          </div>
        </div>
        <div class="paperprint-item display-flex flex-start">
          <span class="paperprint-label" style="margin-bottom: 0;">质量：</span>
          <select class="xpp-select" v-model="xppExportPdfConfig.quality">
            <option value="1">最佳</option>
            <option value="0.7">高</option>
            <option value="0.3">低</option>
          </select>
        </div>
      </div>
      <div slot="footer">
        <el-button type="warning" @click="paperExport">PDF导出</el-button>
      </div>
    </el-dialog>
    <PaperMemberManagement />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import headerEvent from "@plugins/headerEvent";
import XppBreadcrumb from "@components/XppBreadcrumb";
import PaperMemberManagement from "@components/PaperMemberManagement";
import { aliyunPutStream } from "@plugins/aliyunOss";

export default {
  name: "PaperHead",
  mixins: [headerEvent],
  components: {
    XppBreadcrumb,
    PaperMemberManagement
  },
  computed: {
    onlinePeopleList() {
      let list = this.paperPeopleOnlineList;
      return list.reverse().slice(0, 5);
    },
    moreActive() {
      return {
        "xpp-moreactive": this.moreContextMenuState
      };
    },
    printinitProgressStyle() {
      return {
        width: this.printinitProgress + "%"
      };
    },
    authvisiter() {
      return this.xppUserInfo.roleauth === "user";
    },
    inputVerify() {
      return {
        invalid: this.xppExportPdfConfig.inputVerify
      };
    },
    paperIsPwd() {
      return this.xppPaperPassword.ispwd;
    },
    ...mapGetters([
      "paperPeopleOnlineList",
      "paperOperateMode",
      "paperViewZoomInfo",
      "paperViewAll",
      "paperGroupId",
      "xppUserInfo",
      "xppProjectMemberInfo",
      "xppPaperPassword"
    ])
  },
  data() {
    return {
      shareContextMenuState: false,
      moreContextMenuState: false,
      paperbackup: false,
      paperprint: false,
      printinitProgress: 0,
      xppExportPdfDialog: false,
      emailVerifyRegExp: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,
      xppExportPdfConfig: {
        title: "",
        email: "",
        inputVerify: false,
        inputVerifyTime: null,
        emailList: ["asdasd@asd.com"],
        emailKey: {},
        quality: "1"
      }
    };
  },
  methods: {
    routerJump({ target }) {
      if (!target) return;
      this.$router.push(target.dataset.to);
    },
    openShareDialog() {
      this.shareContextMenuState = false;
      this.$SetXppPaperShareDialogInfo({
        id: this.paperGroupId,
        name: document.title
      });
    },
    visitorLogin() {
      sessionStorage.setItem("returnUrl", this.$route.path);
      this.$router.replace({
        name: "Login"
      });
    },
    xppExportPdfDialogHide() {
      this.xppExportPdfDialog = false;
    },
    xppExportPdfDialogShow() {
      this.shareContextMenuState = false;
      this.xppExportPdfConfig = {
        title: `[PDF] ${document.title} (${this.xppUserInfo.nickName})`,
        email: "",
        inputVerify: false,
        emailList: [],
        emailKey: {},
        quality: "1"
      };
      if (this.xppUserInfo.email) {
        this.xppExportPdfConfig.emailList.push(this.xppUserInfo.email);
      }
      this.xppExportPdfDialog = true;
    },
    emailListSplice({ target }) {
      switch (target.dataset.type) {
        case "del":
          let { emailKey, emailList } = this.xppExportPdfConfig;
          let email = target.parentElement.innerText;
          emailKey[email] = false;
          emailList.splice(target.dataset.index, 1);
          break;
      }
    },
    xppEmailVerify() {
      let {
        email,
        emailKey,
        emailList,
        inputVerifyTime
      } = this.xppExportPdfConfig;
      let off = this.emailVerifyRegExp.test(email);
      if (off && !emailKey[email]) {
        emailKey[email] = true;
        emailList.push(email);
        this.xppExportPdfConfig.email = "";
      } else {
        this.xppExportPdfConfig.inputVerify = true;
        clearTimeout(inputVerifyTime);
        this.xppExportPdfConfig.inputVerifyTime = setTimeout(() => {
          this.xppExportPdfConfig.inputVerify = false;
        }, 800);
      }
    },
    paperExport() {
      this.xppExportPdfDialogHide();
      this.paperIframeInit("export", async (ifream, event, pdf) => {
        this.printinitProgress = Math.floor(event * 100);
        if (this.printinitProgress >= 100) {
          this.paperprint = false;
          let email = this.xppExportPdfConfig.emailList.join();
          email && this.$message("文件正在上传，稍后会自动发送邮件");
          await aliyunPutStream(
            document.title + ".pdf",
            pdf.output("blob"),
            async file => {
              if (!file.res) {
                console.warn(file);
                return alert("上传失败");
              }
              if (email) {
                await this.$axios.post(`/dis/p/emailFile`, {
                  text: file.url,
                  email,
                  subject: this.xppExportPdfConfig.title
                });
                this.$message("邮件已发送");
              }

              setTimeout(() => {
                document.body.removeChild(ifream);
              }, 2000);
            }
          );
        }
      });
    },
    paperIframeInit(type, callback) {
      this.paperprint = true;
      let ifream = document.createElement("iframe");
      ifream.setAttribute("frameborder", 0);
      ifream.style.cssText =
        "position: fixed;top: 0px;left: 0px;z-index: -1;opacity: 0;";
      ifream.height = 0;
      ifream.width = 0;
      window.xppprintinitinfo = {
        title: document.title,
        type,
        list: JSON.parse(JSON.stringify(this.paperViewAll)),
        size: this.paperViewZoomInfo
      };
      if (type === "export") {
        window.xppprintinitinfo.quality = this.xppExportPdfConfig.quality;
      }
      window.xppprintready = (event, pdf) => {
        callback(ifream, event, pdf);
      };
      ifream.src = "/response/print.html";
      document.body.appendChild(ifream);
    },
    paperMoreSelect({ target }) {
      this.moreContextMenuState = false;
      switch (target.dataset.type) {
        case "paper_print":
          this.paperIframeInit("print", ifream => {
            this.printinitProgress = 100;
            setTimeout(() => {
              this.paperprint = false;
              ifream.focus();
              ifream.contentWindow.print();
              document.body.removeChild(ifream);
            }, 1000);
          });
          this.printinitProgress = 25;
          break;
        case "paper_backup":
          this.paperbackup = true;
          break;
        case "size_page":
          this.$DiaLoginFoUpDate({
            key: "pageSizeEditView",
            value: true
          });
          this.$UpdataVuexState({ key: "viewMaskShow", data: true });
          break;
        case "paperdataexport":
          let timeend =
            new Date() - new Date(sessionStorage.getItem("paperdataexport"));
          if (timeend <= 10 * 1000) {
            return this.$message("别点太快呦~");
          }
          let iframe = document.createElement("iframe");
          iframe.style.display = "none";
          iframe.src = `/exp/r/backup?parentId=${this.paperGroupId}`;
          document.body.appendChild(iframe);
          sessionStorage.setItem("paperdataexport", new Date());
          setTimeout(() => {
            document.body.removeChild(iframe);
          }, 1000);
          break;
        case "paperdatacover":
          let file = document.createElement("input");
          file.type = "file";
          file.onchange = async () => {
            let xdata = file.files[0];
            if (xdata.name.indexOf(".xcanvas") > -1) {
              let params = new FormData();
              params.append("parentId", this.paperGroupId);
              params.append("file", xdata);
              await this.$axios.post("/exp/r/restore", params);
              location.reload();
            } else {
              this.$message.error("请选择 *.xcanvas 文件");
            }
          };
          file.click();
          break;
        case "paper_password":
          this.$UpdataVuexState({
            key: "xppPaperPassword",
            data: {
              show: true,
              state: target.dataset.state - 0
            }
          });
          break;
      }
    },
    ...mapMutations(["$DiaLoginFoUpDate", "$UpdataVuexState"]),
    ...mapActions(["$SetXppPaperShareDialogInfo"])
  }
};
</script>

<style lang="scss" scoped>
#PaperHead {
  position: relative;
  z-index: 100;
  width: 100vw;
  height: $header-height-default;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #e9ebec;
  box-shadow: 0 0 0 1px #dadada;
  box-sizing: border-box;
}
.paper-head-nav-box,
.paper-head-user-main-box {
  flex: none;
  height: 100%;
  display: flex;
  align-items: center;
}
.paper-logo-nav {
  height: 24px;
  margin-right: 10px;
  cursor: pointer;
  transition: opacity 0.3s;
  &:hover {
    opacity: 0.8;
  }
}

.xpp-user-online-info {
  max-width: 128px;
  margin-right: 8px;
  height: 100%;
  overflow: hidden;
  flex: none;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  img {
    flex: none;
  }
}

.paper-online-item {
  flex: none;
  width: 24px;
  height: 24px;
  margin-left: 8px;
  border-radius: 100%;
  overflow: hidden;
  font-size: 0;
  &:first-child {
    margin-left: 0;
  }
}
.xpp-paper-share-btn {
  cursor: pointer;
  width: 54px;
  height: 32px;
  line-height: 32px;
  color: #fff;
  background-color: #f5a623;
  text-align: center;
  border-radius: 4px;
  .iconfont {
    font-size: 18px;
  }
}

.xpp-paper-share-container,
.xpp-paper-more-container {
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #dbdee6;
  box-shadow: 0 9px 40px 0 rgba(28, 30, 31, 0.14);
  li {
    text-align: left;
    * {
      pointer-events: none;
    }
    &:hover {
      background-color: #f8fafe;
    }
  }
}

.xpp-paper-share-container {
  width: 255px;
  .iconfont {
    font-size: 18px;
    margin-right: 10px;
  }
  li {
    padding: 17px 40px 14px 20px;
    border-bottom: 1px solid #f2f2f2;
    font-size: 14px;
    color: #373839;
    cursor: pointer;
    &:last-child {
      border-bottom: none;
    }
    &:hover {
      background-color: #f8fafe;
    }
    p {
      padding-left: 28px;
      margin-top: 5px;
      color: #9ba1a5;
      font-size: 12px;
    }
  }
}
.xpp-paperview-weight {
  font-size: 12px;
  line-height: 16px;
  margin-left: 0.5em;
}
.xpp-paper-more-btn {
  width: 42px;
  height: 49px;
  line-height: 49px;
  text-align: center;
  cursor: pointer;
  outline: none;
  &.xpp-moreactive {
    color: #f5a623;
  }
}
.xpp-paper-more-container {
  width: 240px;
  .iconfont,
  [class^="el-icon-"] {
    color: #c2c6d1;
    font-size: 16px;
    margin-right: 10px;
  }
  [class^="el-icon-"] {
    font-weight: bold;
  }
  li {
    cursor: pointer;
    height: 42px;
    line-height: 42px;
    padding-left: 16px;
    padding-right: 16px;
    color: #8e959a;
    font-size: 13px;
    font-weight: 600;
    &:nth-child(1) {
      .iconfont {
        font-size: 20px;
      }
    }
    &:nth-child(2) {
      border-bottom: 1px solid #f2f2f2;
    }
  }
}

.paperbackup-title {
  background-color: #fff;
  font-size: 16px;
  line-height: 1.5;
}
.paperbackup-select-item {
  padding: 20px 30px;
  background-color: #fff;
  border: 1px solid #dbdee6;
  margin-bottom: 20px;
  border-radius: 4px;
  cursor: pointer;
  * {
    pointer-events: none;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    background-color: #ebeef2;
    .el-icon-download,
    .el-icon-upload2 {
      color: #484d57;
    }
  }
  .el-icon-download,
  .el-icon-upload2 {
    font-weight: bold;
    font-size: 16px;
    color: #91949a;
  }
  div {
    margin-left: 30px;
  }
  b {
    font-size: 14px;
    font-weight: 600;
    color: #373839;
  }
  span {
    color: #9197a3;
    font-weight: 500;
    font-size: 12px;
  }
}

.paperprint-title {
  line-height: 1.5;
  font-size: 16px;
  .el-icon-close {
    font-size: 22px;
    cursor: pointer;
  }
}
.paperprint-body {
  p {
    padding-top: 20px;
    color: #5f5f69;
    font-size: 13px;
    margin-bottom: 20px;
    line-height: 1.8;
  }
}
.paperprint-item {
  margin-bottom: 20px;
  .paperprint-label {
    display: block;
    font-size: 12px;
    color: #373839;
    margin-bottom: 10px;
    font-weight: 600;
  }
  .xpp-input-text {
    height: 22px;
    line-height: 22px;
    width: 410px;
  }
}
.paperprint-email-box {
  height: 120px;
  background-color: #fff;
  border: 1px solid #dbdee6;
  padding: 13px;
  overflow: auto;
  line-height: 1.8;
  user-select: none;
  span {
    font-size: 14px;
    font-weight: 700;
    padding: 3px 12px;
    height: 24px;
    line-height: 24px;
    border: 1px solid #dbdee6;
    background-color: #f8f9fa;
    border-radius: 4px;
    margin: 0 6px 8px 0;
    vertical-align: top;
  }
  b,
  i {
    vertical-align: middle;
  }
  i {
    width: 1.2em;
    height: 1.2em;
    line-height: 1.2em;
    margin-left: 0.7em;
    font-size: 14px;
    border-radius: 100%;
    text-align: center;
    cursor: pointer;
    &:hover {
      background-color: #f03434;
      color: #fff;
    }
  }
}
.xpp-printinit-progress {
  background-color: #eee;
  height: 4px;
  overflow: hidden;
  border-radius: 4px;
  font-size: 0;
  div {
    height: 100%;
    border-radius: 4px;
    transition: width 0.2s ease-out;
    background-color: #f99000;
  }
}

.el-loading-parent--relative {
  margin-top: 20px;
  height: 45px;
}
</style>
<style lang="scss">
.el-breadcrumb__inner.is-link {
  font-weight: 400;
  color: #8e959a;
}

.el-breadcrumb__item:last-child .el-breadcrumb__inner {
  font-weight: 600;
  color: #373839;
  &:hover {
    font-weight: 600;
    color: #373839;
  }
}

.paperbackup-dialog {
  border-radius: 4px;
  .el-dialog__header {
    border-bottom: 1px solid #dbdee6;
    padding-bottom: 20px;
  }
  .el-dialog__body {
    background-color: #f8f9fa;
  }
}

.paperprint-dialog {
  border-radius: 4px;
  .el-dialog__header {
    padding: 20px;
    border-bottom: 1px solid #dbdee6;
  }
  .el-dialog__body {
    padding: 20px;
  }
}
</style>
