<template>
  <div class="project-list-container" v-loading="viewload">
    <div class="project-search-input">
      <input type="text" v-model="searchText" />
      <i class="el-icon-search" v-if="!searchText"></i>
    </div>
    <div class="list-container" @click="nav">
      <div
        class="list-scroll-box xpp-hide-scroll"
        :class="{ 'no-result': noResult }"
      >
        <template v-for="(tit, index) in viewlist">
          <ul class="cc-ll1" v-if="tit.child.length" :key="tit.id">
            <li class="text-overflow">
              <i class="iconfont icon-nav-project-icon"></i
              ><b>{{ tit.name }}</b>
            </li>
            <ul
              class="cc-ll2"
              v-if="tit.child"
              :class="{ 'overflow-show': tit.open }"
            >
              <li
                :class="{
                  'item-active-router':
                    item.sourceId === paperGroupId || item.id === paperGroupId
                }"
                v-for="(item, idx) in tit.child"
                :key="item.id"
                :data-type="index"
                :data-idx="idx"
              >
                <i class="iconfont icon-nav-canvas-icon"></i
                ><span class="text-overflow">{{ item.name }}</span
                ><i class="iconfont icon-favorites-icon" v-if="index === 0"></i>
              </li>
            </ul>
            <li
              class="list-item-more"
              v-if="tit.child.length > 6"
              @click="tit.open = !tit.open"
            >
              {{ tit.open ? "收起" : `查看更多（${tit.child.length - 6}）` }}
            </li>
          </ul>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "XppAllProjectList",
  props: {
    viewOff: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    xppProjectList() {
      return this.xppUserProjectList.map(item => {
        item.child = item.paperList;
        return item;
      });
    },
    viewarr() {
      let list = [
        {
          name: "收藏",
          id: "xppCollectionList",
          child: this.xppCollectionList
        },
        {
          name: "收到的Paper",
          id: "xppReceivelist",
          child: this.receivelist
        }
      ];
      let data = JSON.stringify(
        [...list, ...this.xppProjectList].map(item => {
          item.open = false;
          return item;
        })
      );
      return data;
    },
    viewlist() {
      let arr = JSON.parse(this.viewarr);
      let list = [];
      if (this.searchText) {
        let num = 0;
        list = arr.map(item => {
          let child = item.child.filter(dd => {
            let type = dd.name.indexOf(this.searchText) >= 0;
            type && num++;
            return type;
          });
          item.child = child;
          return item;
        });
        if (!num) list = [];
      } else {
        list = arr;
      }
      return list;
    },
    noResult() {
      return this.viewlist.length === 0;
    },
    ...mapGetters([
      "xppUserInfo",
      "xppCompanyId",
      "paperGroupId",
      "xppCollectionList",
      "xppUserProjectList"
    ])
  },
  watch: {
    viewOff(val) {
      val && this.init();
    }
  },
  data() {
    return {
      viewload: false,
      firstUp: false,
      list: [],
      receivelist: [],
      searchText: ""
    };
  },
  methods: {
    async init() {
      if (this.firstUp) return;
      this.firstUp = true;
      this.viewload = true;
      let { list } = await this.$axios.get("/dis/p/receivelist", {
        params: {
          userId: this.xppUserInfo.id
        }
      });
      this.receivelist = list.map(item => item.paper);
      await this.$GetCollectionList();
      await this.$UpdataUserProjectList();
      this.viewload = false;
    },
    nav({ target }) {
      if (target.dataset.idx) {
        let { idx, type } = target.dataset;
        let data = this.viewlist[type].child[idx];
        let params = {};
        switch (target.dataset.type) {
          case "0":
            params.name = "Paper";
            params.params = { id: data.sourceId };
            break;
          case "1":
            params.name = "Paper";
            params.params = { id: data.id };
            // params.path = "/s/" + ？
            break;
          default:
            console.log("一般类型");
            break;
        }
        this.$emit("back");
        window.open("/paper/" + params.params.id, "_blank", "noopener");
      }
    },
    ...mapActions(["$GetCollectionList", "$UpdataUserProjectList"])
  }
};
</script>

<style lang="scss" scoped>
.project-list-container {
  padding-top: 20px;
  overflow: hidden;
  .list-container {
    padding-top: 10px;
    padding-bottom: 10px;
    position: relative;
    ul {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
  .iconfont {
    font-size: 16px;
    color: #e8ebef;
  }
  .list-item-more {
    color: #999;
    font-size: 14px;
    padding: 6px 12px;
    font-weight: 500;
    cursor: pointer;
    transition: color 0.2s;
    margin-top: 5px;
    &:hover {
      color: #ddd;
      text-decoration: underline;
    }
  }
}
.list-scroll-box {
  height: calc(80vh - 240px);
  overflow: auto;
  &.no-result {
    position: relative;
    &::before {
      content: "无搜索结果";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      line-height: 40vh;
      color: #999;
      font-size: 16px;
      text-align: center;
      z-index: 2;
      background-color: #fff;
    }
  }
}
.project-search-input {
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  input {
    padding: 0;
    width: 100%;
    height: 26px;
    line-height: 26px;
    border-bottom: 1px solid #e9ebef;
    transition: border-bottom-color 0.2s;
    &:focus,
    &:hover {
      border-bottom-color: #f99000 !important;
      & + i {
        color: #f99000;
      }
    }
  }
  .el-icon-search {
    position: absolute;
    top: 14px;
    right: 12px;
    font-size: 18px;
    color: #c2c6d1;
    transition: color 0.2s;
  }
}
.cc-ll1 {
  > li {
    height: 20px;
    line-height: 20px;
  }
  .icon-nav-project-icon {
    margin-right: 9px;
  }
  b {
    font-size: 14px;
    color: #373839;
  }
}
.cc-ll2 {
  max-height: 200px;
  overflow: hidden;
  &.overflow-show {
    max-height: none;
  }
  li {
    height: 30px;
    line-height: 30px;
    padding-left: 7px;
    padding-right: 7px;
    border-radius: 4px;
    margin-bottom: 4px;
    cursor: pointer;
    &:hover {
      background-color: #f5f7fa;
    }
    &.item-active-router {
      .icon-nav-canvas-icon,
      span {
        color: #f99000;
      }
    }
  }
  i,
  span {
    pointer-events: none;
  }
  .icon-nav-canvas-icon {
    margin-right: 7px;
  }
  .icon-favorites-icon {
    float: right;
    margin-top: 7px;
  }
  span {
    display: inline-block;
    font-size: 12px;
    color: #9197a3;
    max-width: 80%;
    vertical-align: middle;
  }
}
</style>
